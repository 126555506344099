<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級列表
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              新增會員等級
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">新增會員等級</h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <!-- -->
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="等級名稱" label-for="name">
              <b-form-input id="name" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.name"
                :state="v$.form.name.$error ? false : null" :disabled="isShow"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>
            <!-- -->
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="排序" label-for="order">
              <b-form-input id="order" class="mb-2 mr-sm-2 mb-sm-0" type="number" v-model="form.order"
                :state="v$.form.order.$error ? false : null" :disabled="isShow"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.order.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>
            <!-- -->
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="等級代碼" label-for="code">
              <b-form-input id="code" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.code"
                :state="v$.form.code.$error ? false : null" :disabled="isShow"></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.code.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>
            <!-- -->
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="等級說明" label-for="description">
              <b-form-textarea id="description" class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="form.description"
                :state="v$.form.description.$error ? false : null" :disabled="isShow" row="6"></b-form-textarea>
              <b-form-invalid-feedback :state="!v$.form.description.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="是否啟用" label-for="is_enabled">
              <b-form-checkbox id="is_enabled" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.is_enabled"
                :disabled="isShow"></b-form-checkbox>
            </b-form-group>

            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="有效期間">
              <div class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center">
                <datepicker placeholder="請選擇日期" v-model="start_at" bootstrap-styling format="yyyy-MM-dd" :language="zh"
                  :disabled="isShow" :disabledDates="disabledDates" clear-button></datepicker>
                <span class="mx-2">~</span>
                <datepicker placeholder="請選擇日期" v-model="end_at" bootstrap-styling format="yyyy-MM-dd" :language="zh"
                  :disabled="isShow" :disabledDates="disabledDates" clear-button></datepicker>
              </div>
              <b-form-invalid-feedback :state="!v$.start_at.beforeEndAt.$invalid">
                結束時間必須大於起始時間
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" content-cols="12" content-cols-lg="6"
              label="個人頭像">
              <li class="s-image-list-item s-draggable" v-if="image || form.logo_url">
                <img class="s-cropped-image" :src="image || form.logo_url">
                <button v-if="!isShow" class="btn btn-danger s-delete-btn" type="button"
                  @click="deleteImage()">Ｘ</button>
              </li>
              <li class="s-image-list-item" v-else>
                <ImageSelector v-if="!isShow" @input="selectImage">
                  <template #trigger="{ openFileSelector }">
                    <button class="btn s-trigger-btn" @click="openFileSelector">＋ 加入照片</button>
                  </template>
                </ImageSelector>
              </li>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">返回</b-button>
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary"
          class="d-inline-block">
          <b-button variant="success" @click="handleSubmit">
            {{
              isEditing ? "確認修改" : "確認新增"
            }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { isBefore, set, subDays } from "date-fns";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import customerLevelsApi from "@/apis/customer-levels";
import moment from "moment";
import imageApi from "@/apis/image";
import ImageSelector from '@/components/ImageSelector'

export default {
  components: {
    Datepicker, ImageSelector
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      zh,
      disabledDates: { to: subDays(new Date(), 1) },
      isShow: false,
      isEditing: false,
      isLoading: false,
      form: {
        name: null,
        order: 1,
        code: null,
        is_enabled: true,
        logo_url: null,
      },
      level: {},
      start_at: null,
      end_at: null,
      image: null
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        order: { required },
        description: { required },
        code: {},
        logo_url: {}
      },
      start_at: {
        beforeEndAt: () => {
          if (!this.start_at || !this.end_at) {
            return true;
          }

          const startDate = set(new Date(this.start_at), {
            hours: 0,
            minutes: 0,
          })
          const endDate = set(new Date(this.end_at), {
            hours: 23,
            minutes: 59,
          })
          return isBefore(startDate, endDate);
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;

        if (this.$route.name === 'CustomerLevelsEdit') {
          this.isEditing = true;

          const { data } = await customerLevelsApi.show(this.$route.params.id)

          this.level = data.data;
          this.form = data.data;
          this.form.is_enabled = !!this.level.is_enabled;
          this.start_at = this.level.start_at
          this.end_at = this.level.end_at
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      try {
        const result = await this.v$.$validate();
        if (!result) return;

        if (this.image) {
          const resp = await this.uploadImage();
          if (!(resp.status && resp.status === 200)) {
            return;
          }
        }

        this.isLoading = true;

        if (this.isEditing === true) {
          await customerLevelsApi.update(
            this.level.id,
            {
              ...this.form,
              start_at: this.start_at ? moment(this.start_at).format('YYYY-MM-DD 00:00:00') : null,
              end_at: this.end_at ? moment(this.end_at).format('YYYY-MM-DD 23:59:59') : null,
              config: {}
            }
          );

          this.$swal('更新成功', '', 'success');
        } else {
          await customerLevelsApi.create(
            {
              ...this.form,
              start_at: this.start_at ? moment(this.start_at).format('YYYY-MM-DD 00:00:00') : null,
              end_at: this.end_at ? moment(this.end_at).format('YYYY-MM-DD 23:59:59') : null,
              config: {
                dummy: 'acmc'
              },
            }
          );

          this.$swal('送出成功！', '', 'success');
        }

        this.$router.push({ name: "CustomerLevelsList" });
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', '', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.$router.push({
        name: "CustomerLevelsList"
      });
    },
    async uploadImage() {
      try {
        let response = await imageApi.uploadByBase64(this.image);
        this.form.logo_url = response.data.data.image.url
        return response
      } catch (error) {
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    selectImage(image) {
      this.image = image;
    },
    deleteImage() {
      this.image = null;
      this.form.logo_url = null;
    },
  }
}

</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: 7rem;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
